/**
 * read
 */
import { CountTotalHitsForPaidReadRequest } from "./read/CountTotalHitsForPaidReadRequest"
import { CountTotalHitsReadRequest } from "./read/CountTotalHitsReadRequest"
import { GetFavoriteUserListReadRequest } from "./read/GetFavoriteUserListReadRequest"
import { SearchUserProfileListForPaidReadRequest } from "./read/SearchUserProfileListForPaidReadRequest"
import { SearchUserProfileListReadRequest } from "./read/SearchUserProfileListReadRequest"

export const UserProfileSearch = {
  /**
   * read
   */
  CountTotalHitsForPaidReadRequest,
  CountTotalHitsReadRequest,
  GetFavoriteUserListReadRequest,
  SearchUserProfileListForPaidReadRequest,
  SearchUserProfileListReadRequest,
}
