/**
 * read
 */
import { CountTotalHitsReadRequest } from "./read/CountTotalHitsReadRequest"
import { GetCurrentUserProfileReadRequest } from "./read/GetCurrentUserProfileReadRequest"
import { GetUserProfileReadRequest } from "./read/GetUserProfileReadRequest"
import { SearchUserProfileListReadRequest } from "./read/SearchUserProfileListReadRequest"

export const UserProfileSearch = {
  /**
   * read
   */
  CountTotalHitsReadRequest,
  GetCurrentUserProfileReadRequest,
  GetUserProfileReadRequest,
  SearchUserProfileListReadRequest,
}
