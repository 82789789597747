/**
 * プロフィール検索に関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { ParticularReadAcl } from "~/config/acl/accessControlList"
import { StandardApplicationError } from "~/errorHandlers"
import { UserProfileSearchResultView } from "~/backendApis/viewModel"
import { clAuth } from "~/clAuth"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
// 検索パラメーター（条件）
export interface SearchParams {
  /**
   * フリーワード
   */
  searchWord: string
  /**
   * 職種大カテのID
   */
  smallJobCategoryIds: number[]
  /**
   * 稼働時間
   */
  availableUptimeIds: number[]
  /**
   * 最終アクセス（N日以内）
   */
  sinceLastAccessDays: number | null
  /**
   * 居住地域
   */
  prefectureIds: number[]
  /**
   * 稼働形態（副業かフリーランス）
   */
  workingFormId?: number | undefined
  /**
   * 最低年齢
   */
  minAge: number | null
  /**
   * 最高年齢
   */
  maxAge: number | null
}
// 検索結果
interface SearchResult {
  /**
   * 条件にヒットしたプロフィール数
   */
  numberOfTotalHits: number | null // 検索条件が設定されていない場合はヒット数を表示したくないため、nullが格納される。
}
interface ProfileSearchListState {
  /**
   * 現在のページ数
   */
  currentPage: number
  /**
   * 検索のパラメーター
   */
  searchParams: SearchParams
  /**
   * 条件にヒットしたプロフィール数
   */
  searchResult: SearchResult | null
  /**
   * プロフィールのリスト
   */
  profiles: UserProfileSearchResultView[]
  /**
   * プロフィールを取得中（バックエンドと通信中）かどうかを示すフラグ
   */
  isFetchingProfiles: boolean
  /**
   * 次のページが取得可能かどうかを示すフラグ
   */
  canFetchNextPage: boolean
}
const defaultSearchParams = {
  searchWord: "",
  smallJobCategoryIds: [],
  availableUptimeIds: [],
  sinceLastAccessDays: null,
  prefectureIds: [],
  workingFormId: 1,
  minAge: null,
  maxAge: null,
}
const makeDefaultState = () => ({
  currentPage: 1,
  searchParams: { ...defaultSearchParams },
  searchResult: null,
  profiles: [],
  isFetchingProfiles: false,
  canFetchNextPage: true,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProfileSearchListState = Vue.observable<ProfileSearchListState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.currentPage = defaultState.currentPage
  state.searchParams = defaultState.searchParams
  state.searchResult = defaultState.searchResult
  state.profiles = defaultState.profiles
  state.isFetchingProfiles = defaultState.isFetchingProfiles
  state.canFetchNextPage = defaultState.canFetchNextPage
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const uniquifyList = (profiles: UserProfileSearchResultView[]) =>
  profiles.reduce(
    (
      sum: UserProfileSearchResultView[],
      profile: UserProfileSearchResultView
    ) => {
      // まだ配列（sum）に追加されていないプロフィール(UID)の場合のみ配列（sum）に加える。
      if (
        sum.every(
          (s: UserProfileSearchResultView) =>
            s.universalUid !== profile.universalUid
        )
      ) {
        sum.push(profile)
      }
      return sum
    },
    []
  )
const mutations = {
  resetProfileList() {
    state.currentPage = 1
    state.profiles = []
    state.canFetchNextPage = true
    state.searchResult = null
  },
  increasePageNumber() {
    state.currentPage += 1
  },
  setSearchParams(parmas: SearchParams): void {
    state.searchParams = parmas
  },
  setSearchResult(searchResult: SearchResult) {
    state.searchResult = searchResult
  },
  appendProfiles(profiles: UserProfileSearchResultView[]) {
    // idをキーに重複を除外している。
    const uniqueProfiles = uniquifyList([...state.profiles, ...profiles])
    state.profiles = uniqueProfiles
  },
  startFetching() {
    state.isFetchingProfiles = true
  },
  finishFetching() {
    state.isFetchingProfiles = false
  },
  setDownCanFetchNextPageFlag() {
    state.canFetchNextPage = false
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get currentPage(): number {
    return state.currentPage
  },
  get profiles(): UserProfileSearchResultView[] {
    return state.profiles
  },
  get exists(): boolean {
    return state.profiles.length > 0
  },
  get hasAnySearchParams(): boolean {
    return (
      !!getters.searchParams.searchWord.trim() ||
      getters.searchParams.smallJobCategoryIds.length > 0 ||
      !!getters.searchParams.sinceLastAccessDays ||
      getters.searchParams.availableUptimeIds.length > 0 ||
      getters.searchParams.prefectureIds.length > 0 ||
      !!getters.searchParams.workingFormId ||
      !!getters.searchParams.minAge ||
      !!getters.searchParams.maxAge
    )
  },
  get searchParams(): SearchParams {
    return state.searchParams
  },
  get searchResult(): SearchResult | null {
    return state.searchResult
  },
  get isFetchingProfiles(): boolean {
    return state.isFetchingProfiles
  },
  get canFetchNextPage(): boolean {
    return state.canFetchNextPage
  },
  /**
   * リスト上に保持しているプロフィールのリストからIDに該当するものを取得する。
   */
  getProfileByIdFromList: (
    userId: string
  ): UserProfileSearchResultView | null =>
    state.profiles.find((p) => p.universalUid === userId) || null,
  /**
   * リスト上に保持しているプロフィールのリストからIDに該当するものを取得する。
   * リスト上に存在しない場合は、リモート（バックエンド）から取得する。
   */
  getProfileByIdFromListOrRemote: async (
    userId: string
  ): Promise<UserProfileSearchResultView | null> => {
    if (clAuth.userId === userId) {
      return await new BackendApi.User.UserProfileSearch.GetUserProfileReadRequest(
        {
          universalUid: userId,
          isRequiredLatest: true,
        }
      ).get()
    }

    const profileFromList = getters.getProfileByIdFromList(userId)
    if (profileFromList) {
      return profileFromList
    }

    const profileFromRemote = await (clAuth.loggedIn
      ? new BackendApi.User.UserProfileSearch.GetUserProfileReadRequest({
          universalUid: userId,
        }).get()
      : BackendApi.general.userProfileSearch.read.getUserProfile({
          universalUid: userId,
        }))
    return profileFromRemote
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
// アカウントの状態ごとに実行する検索処理が分かれている。
export const makeSearcher = (searchParams: SearchParams, page?: number) => {
  const searcherList = [
    {
      /**
       * 未認証ユーザー
       */
      condition: !clAuth.loggedIn,
      execute: () =>
        BackendApi.general.userProfileSearch.read.searchUserProfileList({
          ...searchParams,
          page: page || 1,
        }),
      countTotalHits: () =>
        BackendApi.general.userProfileSearch.read.countTotalHits(searchParams),
    },
    {
      /**
       * 認証済みユーザー
       */
      condition: clAuth.loggedIn && !clAuth.corporationId,
      execute: () =>
        new BackendApi.User.UserProfileSearch.SearchUserProfileListReadRequest({
          ...searchParams,
          page: page || 1,
        }).get(),
      countTotalHits: () =>
        new BackendApi.User.UserProfileSearch.CountTotalHitsReadRequest(
          searchParams
        ).get(),
    },
    {
      /**
       * 企業メンバー（無料）
       */
      condition:
        clAuth.loggedIn &&
        clAuth.corporationId &&
        !clAuth.hasPermission(
          ParticularReadAcl.FULL_PARAMETER_USER_PROFILE_SEARCH
        ),
      execute: () =>
        new BackendApi.CorporationMember.UserProfileSearch.SearchUserProfileListReadRequest(
          {
            corporationId: clAuth.corporationIdOrFail,
            ...searchParams,
            page: page || 1,
          }
        ).get(),
      countTotalHits: () =>
        new BackendApi.CorporationMember.UserProfileSearch.CountTotalHitsReadRequest(
          {
            corporationId: clAuth.corporationIdOrFail,
            ...searchParams,
          }
        ).get(),
    },
    {
      /**
       * 企業メンバー（有料）
       */
      condition:
        clAuth.loggedIn &&
        clAuth.corporationId &&
        clAuth.hasPermission(
          ParticularReadAcl.FULL_PARAMETER_USER_PROFILE_SEARCH
        ),
      execute: () =>
        new BackendApi.CorporationMember.UserProfileSearch.SearchUserProfileListForPaidReadRequest(
          {
            corporationId: clAuth.corporationIdOrFail,
            ...searchParams,
            page: page || 1,
          }
        ).get(),
      countTotalHits: () =>
        new BackendApi.CorporationMember.UserProfileSearch.CountTotalHitsForPaidReadRequest(
          {
            corporationId: clAuth.corporationIdOrFail,
            ...searchParams,
          }
        ).get(),
    },
  ]
  const targetSearcher = searcherList.find((f) => f.condition)
  if (!targetSearcher) {
    throw new StandardApplicationError("検索時のアカウント状態が不正です")
  }
  return targetSearcher
}
const actions = {
  /**
   * 検索パラメーターを更新する。
   */
  updateSearchParams(params: SearchParams) {
    mutations.setSearchParams(params)
  },
  /**
   * 現在設定されている検索パラメータを使って新しく取得する。
   */
  async fetchNew() {
    mutations.startFetching() // 取得処理開始
    mutations.resetProfileList() // リストの情報を初期化

    const initialPage = 1
    const profileList = await makeSearcher(getters.searchParams, initialPage)
      .execute()
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })
    // 検索条件が設定されている場合のみヒット数を設定する。
    mutations.setSearchResult({
      numberOfTotalHits: getters.hasAnySearchParams
        ? profileList.numberOfTotalHits
        : null,
    })

    if (profileList.numberOfTotalPages <= initialPage) {
      mutations.setDownCanFetchNextPageFlag() // プロフィールが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendProfiles(profileList.list)

    // プロフィール検索イベントを記録
    saveProfileSearchedEventIfCorporationAccount()
  },
  /**
   * 現在設定されている検索パラメータを利用して次のページのプロフィールを取得する。
   */
  async fetchNextPage() {
    if (getters.isFetchingProfiles || !getters.canFetchNextPage) {
      // 現在プロフィールを取得中の場合は何もしない。
      return
    }

    mutations.startFetching() // 取得処理開始

    const nextPage = getters.currentPage + 1
    const profileList = await makeSearcher(getters.searchParams, nextPage)
      .execute()
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (profileList.numberOfTotalPages <= nextPage) {
      mutations.setDownCanFetchNextPageFlag() // プロフィールが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendProfiles(profileList.list)
    mutations.increasePageNumber()

    // プロフィール検索イベントを記録
    saveProfileSearchedEventIfCorporationAccount()
  },
}

/**
 * 企業アカウントで検索している場合のみプロフィール検索イベントを記録。
 */
const saveProfileSearchedEventIfCorporationAccount = () => {
  if (clAuth.corporationId) {
    BackendApi.CorporationMember.eventLog.write.logAll({
      universalCorporationId: clAuth.corporationIdOrFail,
      version: 1,
      type: "user_profile_searched_event",
      items: [
        {
          searchWord: getters.searchParams.searchWord,
          smallJobCategoryIds: getters.searchParams.smallJobCategoryIds,
          availableUptimeIds: getters.searchParams.availableUptimeIds,
          sinceLastAccessDays:
            getters.searchParams.sinceLastAccessDays || undefined,
          prefectureIds: getters.searchParams.prefectureIds,
          minAge: getters.searchParams.minAge || undefined,
          maxAge: getters.searchParams.maxAge || undefined,
          page: getters.currentPage,
        },
      ],
    })
  }
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const profileSearchList = { getters, actions }
